<template>
  <div class="detail-component">
    <h4> {{ name }} </h4>

    <a-radio-group v-model="value" class="custom-radio">
      <a-radio-button v-for="value in values" :key="value.id" :value="value.id" :style="{ backgroundColor: value.id === value ? 'red' : '' }">
        {{ value.name }}
      </a-radio-button>
    </a-radio-group>
  </div>
</template>

<script>
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Detail',
  mixins: [
    newcompetition
  ],
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  computed: {
    current () {
      return this.selectedSport.types.find(({ id }) => this.detail.id === id)
    },
    related () {
      return this.selectedDetails.find(({ id }) => id === this.detail.related) || null
    },
    name () {
      return this.$t('pages.newcompetition.details.' + this.current.name.toLowerCase())
    },
    placeholder () {
      return this.$t('pages.newcompetition.details.placeholder.' + this.current.name.toLowerCase())
    },
    disabled () {
      return this.related && this.related.value === null
    },
    value: {
      get () {
        return this.detail.value || undefined
      },
      set (value) {
        this.setDetail(this.detail.id, value)
      }
    },
    values () {
      return this.current.values.filter((detail) => {
        return !this.related || this.related.value === detail.relation
      })
    }
  }
}
</script>

<style scoped>
  .detail-component {
    margin-bottom: 32px;
  }

  h4 {
    margin-bottom: 12px;
  }
</style>