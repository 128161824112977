<template>
  <subview class="newcompetition-details">
    <view-header>
      <custom-header class="view-header" :router-back="goBackToCompetitionDatePage">
        <template v-slot:default>
          <div>
            <Icon :size="24" type="arrow-left" />

            {{ $t('pages.newcompetition.details.title') }}
          </div>
        </template>

        <template #last>
          <div class="current-step"> {{ $t('pages.newcompetition.step') }} 2/4</div>
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <div v-if="!detailsBasketball" class="detail__wrap">
        <Detail v-for="detail in selectedDetails" :key="detail.id" :detail="detail" />

        <h4> Gender </h4>

        <a-radio-group v-model="gender" class="custom-radio" @change="changeGender">
          <a-radio-button :value="key" v-for="(el, key) in avaliableGenders" :key="el">
            {{ el }}
          </a-radio-button>
        </a-radio-group>
      </div>
      <DetailBasketball v-if="detailsBasketball" />

      <!-- <li v-show="false">
        <div class="sub-title my-4">Referees</div>
        <div class="group">
          <cool-select
            v-model="dataDetails.referees"
            :items="getRef"
            item-value="id"
            item-text="name"
            :placeholder="referees"
          >
            <template #item="{ item }">
              <div>{{ item.name }}</div>
            </template>
          </cool-select>
        </div> -->
    </view-content>

    <view-footer>
      <a-button @click="next" :class="buttonClasses">
        {{ $t('pages.newcompetition.next') }}

        <Icon :size="20" type="arrow-right" />
      </a-button>
    </view-footer>
  </subview>
</template>

<script>
// import { CoolSelect } from 'vue-cool-select'
import Icon from '@/components/Icon'
import Detail from '@/components/NewCompetition/Detail'
import DetailBasketball from '@/components/NewCompetition/DetailBascketbal'
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Details',
  components: {
    Icon,
    Detail,
    DetailBasketball
    // CoolSelect,
  },
  mixins: [
    newcompetition
  ],
  data() {
    return {
      gender: this.$store.state.newcompetition.gender || undefined,
      genders: {
        mixed: "Mixed",
        man: "Man",
        woman: "Woman",
      },
    }
  },
  computed: {
    detailsBasketball() {
      return this.selectedSport.name === "Basketball" ? true : false
    },
    disabled() {
      return !(this.selectedDetails.every(({ value }) => value !== null) && this.gender);
    },
    buttonClasses() {
      const { disabled } = this

      return this.selectedSport.name === "Basketball" ? { disabled: false } : { disabled }
    },
    avaliableGenders() {
      const userGender = this.$store.state.auth.user.gender
      return Object.fromEntries(
        Object.entries(this.genders).filter(([key, value]) => key === userGender || value === 'Mixed')
      );

    }
  },
  methods: {
    changeGender() {
      this.setGender(this.gender);
      this.$store.dispatch('getGenderOpponets', this.gender)
    },
    next() {
      // this.$router.push({ name: 'newcompetition.appearance.default' })
      this.$router.push({ name: 'newcompetition.competitors' })
    },
    goBackToCompetitionDatePage() {
      this.$router.push({ name: "newcompetition.date" });
    }
  }
}
</script>

<style scoped>
h4 {
  margin-bottom: 12px;
}
</style>
