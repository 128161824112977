<template>
  <div class="detail-component">
    <h4 class="detail-component__title">Around the World - Description</h4>

    <p class="detail-component__text">
        Game Process and Rules <br>
        Each player performs 8 throws from certain points with one ball for time. The number of throws is unlimited. The winner is the one who throws at least one ball from each point faster than the opponent.
    </p>
    <p class="detail-component__text">
        Maximum attempt duration (video): 2 minutes (see [example](https://www.notion.so/Disciplines-and-Rules-b43253a2466d4a66a1b1dd6309d27c49) below)
        Each participant performs (records video of his performance) individually. 
    </p>
    <p class="detail-component__text">
        It is not possible to put it on pause. 
    </p>
    <p class="detail-component__text">
        You can cancel the recording and start again an unlimited number of times within the deadline for recording the performance.
    </p>
  </div>
</template>

<script>

export default {
  name: 'DetailBasketball',
}
</script>

<style lang="stylus" scoped>
.newcompetition-view .subview .view-content .detail-component {
    &__title{
      margin: 12px 0 8px
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #EEEEEE;
    }

    &__text {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #7B8B97;
      max-width: 100%;
    }
  }
</style>
